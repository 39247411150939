import React from "react";

const GTMNoScript = ({ gtmId }) => {
  const noscriptElement = document.createElement("noscript");
  noscriptElement.innerHTML = `
<iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}"
            height="0" width="0" style="display:none;visibility:hidden"></iframe>
  `;

  React.useEffect(() => {
    document.body.insertBefore(noscriptElement, document.body.firstChild);
    return () => {
      document.body.removeChild(noscriptElement);
    };
  }, [noscriptElement]);

  return null;
};

export default GTMNoScript;
