import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import {
  esTranslations,
  enTranslations,
  enProduct,
  esProduct,
  enCredenceTwoR,
  esCredenceTwoR,
  enGetStarted,
  esGetStarted,
  enCredenceTab,
  enCredenceEco,
  enCredence3,
  esCredence3,
  enCredenceConnect,
  esCredenceTab,
  esCredenceEco,
  esCredenceConnect,
  enAboutUs,
  esAboutUs,
  enContactUs,
  esContactUs,
  enTermsOfService,
  esTermsOfService,
  enPrivacyStatement,
  esPrivacyStatement,
  enMedia,
  esMedia,
  enBlog,
  esBlog,
  enDigitalId,
  esDigitalId,
  enFaq,
  esFaq,
  enVwc,
  enTap2iDPage,
  enTap2iDMobilePage
} from "./exports.js";

i18n.use(initReactI18next).init({
  fallbackLng: "en",
  lng: "en",
  resources: {
    en: {
      translations: enTranslations,
      product: enProduct,
      credenceTwoR: enCredenceTwoR,
      credenceTab: enCredenceTab,
      credenceEco: enCredenceEco,
      credence3: enCredence3,
      credenceConnect: enCredenceConnect,
      getStarted: enGetStarted,
      aboutUs: enAboutUs,
      contactUs: enContactUs,
      termsOfService: enTermsOfService,
      privacyStatement: enPrivacyStatement,
      media: enMedia,
      blog: enBlog,
      digitalId: enDigitalId,
      faq: enFaq,
      vwcPage: enVwc,
      tap2iDPage: enTap2iDPage,
      tap2iDMobilePage: enTap2iDMobilePage
    },
    es: {
      translations: esTranslations,
      product: esProduct,
      credenceTwoR: esCredenceTwoR,
      credenceTab: esCredenceTab,
      credenceEco: esCredenceEco,
      credence3: esCredence3,
      credenceConnect: esCredenceConnect,
      getStarted: esGetStarted,
      aboutUs: esAboutUs,
      contactUs: esContactUs,
      termsOfService: esTermsOfService,
      privacyStatement: esPrivacyStatement,
      media: esMedia,
      blog: esBlog,
      digitalId: esDigitalId,
      faq: esFaq,
    },
  },
  ns: ["translations", "product"],
  defaultNS: "translations",
});

i18n.languages = ["en", "es"];

export default i18n;
