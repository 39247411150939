import veridos from "../assets/images/Veridos-logo.png";
import thales from "../assets/images/Thales-logo.png";
import visa from "../assets/images/Visa-logo.png";
import idemia from "../assets/images/IDEMIA-logo.png";
import nec from "../assets/images/NEC-logo.png";
import awarePrimary from "../assets/images/Aware_Primary.jpg";

import whyCidLogo1 from "../assets/images/CredenceID-proven-track-record.svg";
import whyCidLogo2 from "../assets/images/Biometric-hardware.png";
import whyCidLogo3 from "../assets/images/Certificate-logo.svg";
import whyCidLogo4 from "../assets/images/Software-code-graphical-representation.svg";
import whyCidLogo5 from "../assets/images/Over-the-air-cloud.png";
import whyCidLogo6 from "../assets/images/Device-management-settings-logo.svg";

import softwareApps from "../assets/svgs/Four-squares-indicating-apps.svg";
import softwareApiSdk from "../assets/svgs/Curly-brackets-in-coding.svg";
import softwareKiosk from "../assets/svgs/Kiosk-mode-logo.svg";
import softwareSecure from "../assets/svgs/Secure-OS-logo.svg";
import softwareAnaytics from "../assets/svgs/Software-analytics-logos.svg";
import softwareCid from "../assets/svgs/Marketplace-symbol.svg";
import softwareDevice from "../assets/svgs/Device-management-logo.svg";
import softwareRemote from "../assets/images/Remote-SW-install.png";
import softwarePreInt from "../assets/images/Pre-integrated-algorithm.png";

import softwareAppsSel from "../assets/svgs/Four-squares-indicating-apps-selected.svg";
import softwareApiSdkSel from "../assets/svgs/Curly-brackets-in-coding-selected.svg";
import softwareKioskSel from "../assets/svgs/Kiosk-mode-logo-selected.svg";
import softwareSecureSel from "../assets/svgs/Secure-OS-logo-selected.svg";
import softwareAnayticsSel from "../assets/svgs/Software-analytics-logos-selected.svg";
import softwareCidSel from "../assets/svgs/Marketplace-selected.svg";
import softwareDeviceSel from "../assets/svgs/Device-management-selected.svg";
import softwareRemoteSel from "../assets/images/Remote-SW-install-selected.png";
import softwarePreIntSel from "../assets/images/Pre-integrated-algo-selected.png";

import innovative1 from "../assets/gifs/Credence-Two-R.gif";
import innovative2 from "../assets/gifs/Credence-Tab.gif";
import innovative3 from "../assets/gifs/CredenceECO.gif";
import innovativeDemo from "../assets/images/Credence-TWO-R-Demo-1.png";
import innovative4 from "../assets/gifs/Credence-Three_Green.gif";
import newLaunchedImg from "../assets/images/home-new-launch.png";

import softwareAuth1 from "../assets/svgs/Reports-logo.svg";
import softwareAuth2 from "../assets/svgs/Device-settings-logo.svg";
import softwareAuth3 from "../assets/svgs/User-setting-logo.svg";
import softwareAuth4 from "../assets/svgs/Device-information-logo.svg";
import softwareAuth5 from "../assets/svgs/Device-location-logo.svg";
import softwareAuth6 from "../assets/svgs/Matching-algorithms-logo.svg";
import softwareAuth7 from "../assets/svgs/Marketplace.svg";
import softwareAuth8 from "../assets/svgs/Notifications-logo.svg";

import soti from "../assets/images/SOTI-logo.png";
import visionlabs from "../assets/images/visionlabs.svg";
import anyDesk from "../assets/images/AnyDesk-logo.png";
import teamViewer from "../assets/images/TeamViewer-logo.png";
import innovatrics from "../assets/images/innovatrics.png";
import aware from "../assets/images/Aware-logo.png";
import cryptoVision from "../assets/images/Cryptovision-logo.png";
import neurotech from "../assets/images/NEURO-Technology-logo.png";
import tech5 from "../assets/images/TECH5-logo.png";

import welcomeDev1 from "../assets/svgs/Coding-graphical-representation.svg";
import welcomeDev2 from "../assets/svgs/Remote-app-distribution.svg";
import welcomeDev3 from "../assets/svgs/Feather-representing-lightweight.svg";
import welcomeDev4 from "../assets/svgs/Biometric-SDK.svg";

import certified1 from "../assets/images/CE-certified-mark.png";
import certified2 from "../assets/images/FCC-certified-mark.png";
import certified3 from "../assets/images/PTCRB-certified-mark.png";
import certified4 from "../assets/images/ISO-certified-mark.png";
import certified5 from "../assets/images/IP65.png";

import productImg1 from "../assets/images/Credence-connect.png";
import productImg2 from "../assets/images/Credence-TWO-R.png";
import productImg3 from "../assets/images/Credence-TAB.png";
import productImg4 from "../assets/images/Credence-ECO.png";
import productImg5 from "../assets/images/Credence-3.png";

import Testimonial2 from "../assets/images/Papua-New-Guinea-Testimonial.png";
import Testimonial3 from "../assets/images/Frost & Sullivan-Testimonial.png";
import Testimonial4 from "../assets/images/Vatebra-Testimonial.png";
import Testimonial5 from "../assets/images/Invixium-Testimonial.png";
import Testimonial6 from "../assets/images/ADO-Technologies-Testimonial.png";
import Testimonial7 from "../assets/images/Fulcrum-Biometrics-Testimonial.png";
import Testimonial8 from "../assets/images/Gemalto-Government-Programs-Testimonial.png";
import Testimonial9 from "../assets/images/Innovatrics-Testimonial.png";
import Testimonial10 from "../assets/images/National-Office-of-WAEC-Testimonial.png";

import TestimonialM2 from "../assets/images/Mob-Papua-New-Guinea-Testimonial.png";
import TestimonialM3 from "../assets/images/Mob-Frost & Sullivan-Testimonial.png";
import TestimonialM4 from "../assets/images/Mob-Vatebra-Testimonial.png";
import TestimonialM5 from "../assets/images/Mob-Invixium-Testimonial.png";
import TestimonialM6 from "../assets/images/Mob-ADO-Technologies-Testimonial.png";
import TestimonialM7 from "../assets/images/Mob-Fulcrum-Biometrics-Testimonial.png";
import TestimonialM8 from "../assets/images/Mob-Gemalto-Government-Programs-Testimonial.png";
import TestimonialM9 from "../assets/images/Mob-Innovatrics-Testimonial.png";
import TestimonialM10 from "../assets/images/Mob-National-Office-of-WAEC-Testimonial.png";

import BannerLogo from "../assets/images/home-IDverification-platform.png";
import BannerLogo2 from "../assets/images/home-digitalID-authentication.png";
import BannerLogo3 from "../assets/images/home-credence3.png";
import VwcMenuImg from "../assets/images/web-portal-menu.svg";
import IdVerifierImg from "../assets/images/phone-verifier-menu.svg";
import Tab2IDMenuImg from "../assets/images/tab2Id-menu.svg";
import DigitalIdOverviewMenu from "../assets/images/digitalid-banner-img.png";

export const NavTabs = [
  {
    key: "home",
    textKey: "home",
    path: "/",
  },
  {
    key: "products",
    textKey: "products",
    path: "/biometric-products",
    dropdownTitle: "product:meet-credence-id-family",
    dropdownPath: "/biometric-products",
    dropdown: [
      {
        text: "product:credence-connect-title",
        img: productImg1,
        path: "/credence-connect-biometric-software",
        imgWidth: "70px",
        imgMobWidth: "30px",
        class: "product-border-bottom-right",
      },
      {
        text: "product:credence-tab-title",
        img: productImg3,
        path: "/credencetab-biometric-tablet",
        imgWidth: "70px",
        imgMobWidth: "30px",
        class: "product-border-bottom",
      },
      {
        text: "product:credence-two-r-title",
        img: productImg2,
        path: "/credencetwo-r-handheld-biometric-device",
        imgWidth: "32px",
        imgMobWidth: "18px",
      },
      {
        text: "product:credence-eco-title",
        img: productImg4,
        path: "/credenceeco-identifier-biometric-reader",
        imgWidth: "37px",
        class: "product-border-right",
        imgMobWidth: "16px",
      },
      {
        text: "product:credence-3-title",
        img: productImg5,
        path: "/credence3-identifier-biometric-reader",
        imgWidth: "37px",
        class: "product-border-right",
        imgMobWidth: "16px",
      },
    ],
  },
  {
    key: "digitalId",
    textKey: "digital-id",
    path: "/digital-id",
    dropdownTitle: "digitalId:meet-digitalId-family",
    dropdownPath: "/digital-id",
    dropdown: [
      {
        text: "digitalId:digital-id-overview-menu",
        img: DigitalIdOverviewMenu,
        path: "/digital-id",
        imgWidth: "60px",
        imgMobWidth: "30px",
        class: "product-border-bottom-right",
      },

      {
        text: "digitalId:tab-2-id-menu-title",
        img: Tab2IDMenuImg,
        path: "/tap2id",
        imgWidth: "60px",
        imgMobWidth: "30px",
        class: "product-border-bottom-right",
      },
      {
        text: "digitalId:phone-verifier-menu-title",
        img: IdVerifierImg,
        path: "/smart-verifier",
        imgWidth: "30px",
        imgMobWidth: "30px",
        class: "product-border-bottom-right",
      },
      {
        text: "digitalId:vwc-menu-title",
        img: VwcMenuImg,
        path: "/vwc-web-portal",
        imgWidth: "57px",
        imgHeight: "75px",
        imgMobWidth: "30px",
        class: "product-border-bottom-right",
      },
    ],
  },
  {
    key: "aboutUs",
    textKey: "about-us",
    path: "/about-us",
  },
  {
    key: "media",
    textKey: "media",
    path: "/media",
  },
  {
    key: "contactUs",
    textKey: "contact-us",
    path: "/contact-us",
  },
];

export const brandLogos = [
  {
    image: veridos,
    altText: "Veridos logo - partner of Credence ID",
  },
  {
    image: thales,
    altText: "Thales logo - partner of Credence ID",
  },
  {
    image: visa,
    altText: "Visa logo - partner of Credence ID",
  },
  {
    image: idemia,
    altText: "IDEMIA logo - partner of Credence ID",
  },
  {
    image: nec,
    altText: "National Electrical Code - NEC logo",
  },
  {
    image: awarePrimary,
    alert: "AWARE logo - partner of Credence ID",
  },
];

export const whyCidData = [
  {
    title: "why-cid-point1-title",
    subtitle: "why-cid-point1-subtitle",
    image: whyCidLogo1,
    altText: "Logo for Credence ID Proven Track Record",
  },
  {
    title: "why-cid-point2-title",
    subtitle: "why-cid-point2-subtitle",
    image: whyCidLogo2,
    altText: "Logo for Credence ID Dedicated Biometric Hardware",
  },
  {
    title: "why-cid-point3-title",
    subtitle: "why-cid-point3-subtitle",
    image: whyCidLogo3,
    altText: "Logo for Credence ID Certification",
    haveImages: [
      {
        image: certified1,
        altText: "Conformité Européenne - CE logo",
      },
      {
        image: certified2,
        altText: "Federal Communications Commission - FCC logo",
      },
      {
        image: certified3,
        altText: "PCS Type Certification Review Board - PTCRB logo",
      },
      {
        image: certified4,
        altText: "Ingress Protection - ISO9001 logo",
      },
      {
        image: certified5,
        altText: "Ingress Protection - IP54 logo",
      },
    ],
  },
  {
    title: "why-cid-point4-title",
    subtitle: "why-cid-point4-subtitle",
    image: whyCidLogo4,
    altText: "Logo for Credence ID Pre Integrated Biometric Software",
  },
  {
    title: "why-cid-point5-title",
    subtitle: "why-cid-point5-subtitle",
    image: whyCidLogo5,
    altText: "Logo for Credence ID Biometric Software Over The Air (SOTA)",
  },
  {
    title: "why-cid-point6-title",
    subtitle: "why-cid-point6-subtitle",
    image: whyCidLogo6,
    altText: "Logo for Credence ID Remote Biometric Device Management",
  },
];

export const software = [
  {
    image: softwareApps,
    altText: "Logo for Credence ID Biometric Apps",
    selectedImage: softwareAppsSel,
    title: "software1",
    text: "software1-text",
  },
  {
    image: softwareApiSdk,
    altText: "Logo for Credence ID Biometric APIs and SDKs",
    selectedImage: softwareApiSdkSel,
    title: "software2",
    text: "software2-text",
  },
  {
    image: softwareKiosk,
    altText: "Logo for Credence ID KIOSK Mode",
    selectedImage: softwareKioskSel,
    title: "software3",
    text: "software3-text",
  },
  {
    image: softwareSecure,
    altText: "Logo for Credence ID Secure OS",
    selectedImage: softwareSecureSel,
    title: "software4",
    text: "software4-text",
  },
  {
    image: softwareAnaytics,
    altText: "Logo for Credence ID Analytics",
    selectedImage: softwareAnayticsSel,
    title: "software5",
    text: "software5-text",
  },
  {
    image: softwareCid,
    altText: "Logo for CID Marketplace",
    selectedImage: softwareCidSel,
    title: "software6",
    text: "software6-text",
  },
  {
    image: softwareDevice,
    altText: "Logo for Credence ID Biometric Device Management",
    selectedImage: softwareDeviceSel,
    title: "software7",
    text: "software7-text",
  },
  {
    image: softwareRemote,
    altText: "Logo for Credence ID Remote Biometric Software Install",
    selectedImage: softwareRemoteSel,
    title: "software8",
    text: "software8-text",
  },
  {
    image: softwarePreInt,
    altText: "Logo for Credence ID Pre Integrated Biometric Algorithm",
    selectedImage: softwarePreIntSel,
    title: "software9",
    text: "software9-text",
  },
];

export const innovative = [
  {
    image: {
      image: innovative4,
      newimage: newLaunchedImg,
      altText1: "Newly Launched!",
      altText: "Credence-3™ Identifier biometric reader",
      width: "70%",
      text1: "section1-image-text1",
      image1: "shield",
      text2: "section1-image-text3",
      image2: "globe",
      text3: "section1-image-text2",
      image3: "battery-full",
    },
    text: {
      title: "section4-title",
      subtitle: "section4-para",
      button: "learn-more",
      link: "/credence3-identifier-biometric-reader",
    },
    demo: {
      title: "demo-video",
      text: "section4-para",
      image: innovativeDemo,
      altText: "Credence-3™ Identifier biometric reader",
    },
  },
  {
    image: {
      image: innovative3,
      altText: "Credence ECO rugged biometric device",
      width: "70%",
      text1: "section1-image-text1",
      image1: "shield",
      text2: "section1-image-text3",
      image2: "globe",
      text3: "section1-image-text2",
      image3: "battery-full",
    },
    text: {
      title: "section3-title",
      subtitle: "section3-para",
      button: "learn-more",
      link: "/credenceeco-identifier-biometric-reader",
    },
    demo: {
      title: "demo-video",
      text: "section3-para",
      image: innovativeDemo,
      altText: "Credence ECO by Credence ID",
    },
  },
  {
    image: {
      image: innovative1,
      altText: "Credence TWO-R rugged biometric device",
      width: "70%",
      text1: "section1-image-text1",
      image1: "shield",
      text2: "section1-image-text3",
      image2: "globe",
      text3: "section1-image-text2",
      image3: "battery-full",
    },
    text: {
      title: "section1-title",
      subtitle: "section1-para",
      button: "learn-more",
      link: "/credencetwo-r-handheld-biometric-device",
    },
    demo: {
      title: "demo-video",
      text: "section1-para",
      image: innovativeDemo,
      altText: "Credence TWO-R by Credence ID",
    },
  },
  {
    image: {
      image: innovative2,
      altText: "Credence TAB rugged biometric tablet",
      width: "70%",
      text1: "section1-image-text1",
      image1: "shield",
      text2: "section2-image-text3",
      image2: "expand-alt",
      text3: "section1-image-text2",
      image3: "battery-full",
    },
    text: {
      title: "section2-title",
      subtitle: "section2-para",
      button: "learn-more",
      link: "/credencetab-biometric-tablet",
    },
    demo: {
      title: "demo-video",
      text: "section2-para",
    },
  },
];

export const languages = [
  {
    text: "English",
    key: "en",
  },
  {
    text: "Spanish",
    key: "es",
  },
];

export const softwareForAuth = [
  {
    image: softwareAuth1,
    altText: "Logo for Credence ID Reports",
    title: "software-point1-title",
    subtitle: "software-point1-para",
  },
  {
    image: softwareAuth2,
    altText: "Logo for Credence ID App Management",
    title: "software-point2-title",
    subtitle: "software-point2-para",
  },
  {
    image: softwareAuth3,
    altText: "Logo for Credence ID User Management",
    title: "software-point3-title",
    subtitle: "software-point3-para",
  },
  {
    image: softwareAuth4,
    altText: "Logo for Credence ID Biometric Device information",
    title: "software-point4-title",
    subtitle: "software-point4-para",
  },
  {
    image: softwareAuth5,
    altText: "Logo for Credence ID Fleet Management",
    title: "software-point5-title",
    subtitle: "software-point5-para",
  },
  {
    image: softwareAuth6,
    altText: "Logo for Credence ID Matching Algorithms",
    title: "software-point6-title",
    subtitle: "software-point6-para",
  },
  {
    image: softwareAuth7,
    altText: "Logo for Credence ID Marketplace",
    title: "software-point7-title",
    subtitle: "software-point7-para",
  },
  {
    image: softwareAuth8,
    altText: "Logo for Credence ID Notifications",
    title: "software-point8-title",
    subtitle: "software-point8-para",
  },
];

export const richSoftware = [
  {
    image: soti,
    altText: "Security Operations Training and Intelligence SOTI logo",
    text: "soti-text",
    imageWidth: "30%",
  },
  {
    image: visionlabs,
    altText: "Vision Labs logo",
    text: "vision-labs-text",
    imageWidth: "40%",
  },
  {
    image: anyDesk,
    altText: "AnyDesk logo",
    text: "any-desk-text",
    imageWidth: "40%",
  },
  {
    image: teamViewer,
    altText: "TeamViewer-logo",
    text: "team-viewer-text",
    imageWidth: "40%",
  },
  {
    image: innovatrics,
    altText: "Innovatrics logo",
    text: "innovatrics-text",
    imageWidth: "40%",
  },
  {
    image: aware,
    altText: "Aware logo",
    text: "aware-text",
    imageWidth: "30%",
  },
  {
    image: cryptoVision,
    altText: "Cryptovision logo",
    text: "crypto-vision-text",
    imageWidth: "40%",
  },
  {
    image: neurotech,
    altText: "NEURO Technology logo",
    text: "neuro-tech-text",
    imageWidth: "40%",
  },
  {
    image: tech5,
    altText: "TECH5 logo",
    text: "tech5-text",
    imageWidth: "30%",
  },
];

export const welcomeDevs = [
  {
    image: welcomeDev1,
    altText: "Logo for Credence ID Open Source Code",
    title: "welcome-devs-point1-title",
    subtitle: "welcome-devs-point1-text",
  },
  {
    image: welcomeDev2,
    altText: "Logo for Credence ID RemoteBiometric  App Distribution",
    title: "welcome-devs-point2-title",
    subtitle: "welcome-devs-point2-text",
  },
  {
    image: welcomeDev3,
    altText: "Logo for Credence ID Lightweight JAR Files",
    title: "welcome-devs-point3-title",
    subtitle: "welcome-devs-point3-text",
  },
  {
    image: welcomeDev4,
    altText: "Logo for Credence ID Biometric SDK",
    title: "welcome-devs-point4-title",
    subtitle: "welcome-devs-point4-text",
  },
];

export const industryVerticalz = [
  {
    title: "ind-column1-title",
    point1: "ind-column1-point1",
    point2: "ind-column1-point2",
    point3: "ind-column1-point3",
  },
  {
    title: "ind-column2-title",
    point1: "ind-column2-point1",
    point2: "ind-column2-point2",
    point3: "ind-column2-point3",
  },
  {
    title: "ind-column3-title",
    point1: "ind-column3-point1",
    point2: "ind-column3-point2",
    point3: "ind-column3-point3",
  },
  {
    title: "ind-column4-title",
    point1: "ind-column4-point1",
    point2: "ind-column4-point2",
    point3: "ind-column4-point3",
  },
];

export const carouselData = [
  // {
  //     quote: "carousel1-quote",
  //     quoter: "carousel1-quoter",
  //     quoterTitle: "carousel1-quoter-title",
  //     image: Testimonial1,
  //     altText: "Mr. Wilber Ross - U.S. Secretary of Commerce"
  // },
  {
    quote: "carousel2-quote",
    quoter: "carousel2-quoter",
    quoterTitle: "carousel2-quoter-title",
    image: Testimonial2,
    imageMobile: TestimonialM2,
    altText: "Mr. Simon Sinai - Electoral Commisioner of Papua New Guinea",
  },
  {
    quote: "carousel3-quote",
    quoter: "carousel3-quoter",
    quoterTitle: "carousel3-quoter-title",
    image: Testimonial3,
    imageMobile: TestimonialM3,
    altText: "Mr. Ram Ravi - Industry Analyst at Frost & Sullivan",
  },
  {
    quote: "carousel4-quote",
    quoter: "carousel4-quoter",
    quoterTitle: "carousel4-quoter-title",
    image: Testimonial4,
    imageMobile: TestimonialM4,
    altText: "Mr. Kunle Akinniran - Managing Director at Vatebra",
  },
  {
    quote: "carousel5-quote",
    quoter: "carousel5-quoter",
    quoterTitle: "carousel5-quoter-title",
    image: Testimonial5,
    imageMobile: TestimonialM5,
    altText: "Mr. Shiraz Kapadia - CEO & President at Invixium",
  },
  {
    quote: "carousel6-quote",
    quoter: "carousel6-quoter",
    quoterTitle: "carousel6-quoter-title",
    image: Testimonial6,
    imageMobile: TestimonialM6,
    altText: "Mr. Alon Ofir - CEO at ADO Technologies",
  },
  {
    quote: "carousel7-quote",
    quoter: "carousel7-quoter",
    quoterTitle: "carousel7-quoter-title",
    image: Testimonial7,
    imageMobile: TestimonialM7,
    altText: "Mr. Ken Nosker - CEO at Fulcrum Biometrics",
  },
  {
    quote: "carousel8-quote",
    quoter: "carousel8-quoter",
    quoterTitle: "carousel8-quoter-title",
    image: Testimonial8,
    imageMobile: TestimonialM8,
    altText: "Mr. Lionel Jean - VP & Director, Gemalto Government Programs",
  },
  {
    quote: "carousel9-quote",
    quoter: "carousel9-quoter",
    quoterTitle: "carousel9-quoter-title",
    image: Testimonial9,
    imageMobile: TestimonialM9,
    altText: "Ms. Nina Suchankova - Business Development at Innovatrics",
  },
  {
    quote: "carousel10-quote",
    quoter: "carousel10-quoter",
    quoterTitle: "carousel10-quoter-title",
    image: Testimonial10,
    imageMobile: TestimonialM10,
    altText: "Mr. Olutise Adenipekun - Head of National Office of WAEC",
  },
];

export const getStarter = {
  title: "get-starter-kit",
  line1: "get-starter-kit-li1",
  line2: "get-starter-kit-li2",
};

export const homeCarouselData = [
  {
    image: {
      image: BannerLogo2,
      altText: "Credence ID -digital ID-authentication-for-the-mobile- world",
      bannerClass: "home-banner-two",
    },
    text: {
      bannerTitle: "banner-title2",
      bannerSubtitle: "banner-subtitle2",
    },
    button: [
      {
        buttonText: "request-demo",
        buttonLink: "/contact-us-digitalId",
      },
    ],
  },
  {
    image: {
      image: BannerLogo,
      altText: "Credence ID-ID verification-platform-for-the digital-world",
      bannerClass: "home-banner-one",
    },
    text: {
      bannerTitle: "banner-title1",
      bannerSubtitle: "banner-subtitle1",
    },
    button: [
      {
        buttonText: "contact-sales",
        buttonLink: "/contact-us-generalEnquiry",
        outLine: true,
      },
    ],
  },
  {
    image: {
      image: BannerLogo3,
      altText:
        "Credence ID - Credence-3-enhanced-biometric-enrollment-and-verification-solution",
      imageClass: "home-banner-logo",
      bannerClass: "home-banner-three",
      socialIconClass: "home-banner-icon",
    },
    text: {
      bannerTitle: "banner-title3",
      // bannerSubtitle: "banner-subtitle3",
    },
    button: [
      {
        buttonText: "learn-more",
        buttonLink: "/credence3-identifier-biometric-reader",
      },
    ],
  },
];
