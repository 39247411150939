import React, { Suspense, lazy } from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Header from "./components/Header/Header.js";
// import ReactGA from "react-ga";
import GTMService from "./service/GTMService.js";
import GTMNoScript from "./service/GTMNoService.js";

// Lazy-loaded components
const Home = lazy(() => import("./containers/Home/Home.js"));
const Product = lazy(() => import("./containers/Product/Product.js"));
const CredenceConnect = lazy(() =>
  import("./containers/ProductDetails/CredenceConnect.js")
);
const CredenceTwoR = lazy(() =>
  import("./containers/ProductDetails/CredenceTwoR.js")
);
const CredenceTab = lazy(() =>
  import("./containers/ProductDetails/CredenceTab.js")
);
const CredenceEco = lazy(() =>
  import("./containers/ProductDetails/CredenceEco.js")
);
const Credence3 = lazy(() =>
  import("./containers/ProductDetails/Credence3.js")
);
const VwCPage = lazy(() => import("./containers/DigitalIDNew/VwCPage.js"));
const GetStarted = lazy(() => import("./containers/GetStarted/GetStarted.js"));
const AboutUs = lazy(() => import("./containers/AboutUs/AboutUs.js"));
const VwCPortal = lazy(() =>
  import("./containers/DigitalIdProduct/VwCPortal.js")
);
// const PhoneVerifier = lazy(() =>
//   import("./containers/DigitalIdProduct/PhoneVerifier.js")
// );
//const Tab2Id = lazy(() => import("./containers/DigitalIdProduct/Tab2Id.js"));
const Tap2iD = lazy(() => import("./containers/DigitalIDNew/Tap2iDPage.js"));
const Tap2iDMobileApp = lazy(() =>
  import("./containers/DigitalIDNew/Tap2iDMobilePage.js")
);

const Faq = lazy(() => import("./containers/Faq/Faq.js"));
const ContactUs = lazy(() => import("./containers/ContactUs/ContactUs.js"));
const ContactUsConfirmation = lazy(() =>
  import("./containers/ContactUsConfirmation/ContactUsConfirmation.js")
);
const Media = lazy(() => import("./containers/Media/Media.js"));
const TermsOfService = lazy(() =>
  import("./containers/TermsOfService/TermsOfService.js")
);
const PrivacyStatement = lazy(() =>
  import("./containers/PrivacyStatement/PrivacyStatement.js")
);
const MediaDetails = lazy(() =>
  import("./containers/MediaDetails/MediaDetails.js")
);
const MediaPapers = lazy(() =>
  import("./containers/MediaDetails/MediaPapers.js")
);
const Sitemap = lazy(() => import("./containers/Sitemap/Sitemap.js"));
const Slp = lazy(() => import("./containers/Sitemap/Slp.js"));

function App() {
  const { i18n } = useTranslation();
  const [preferredLanguage, setPreferredLanguage] = React.useState("en");
  const gtmId = "GTM-MVXTMPL2";

  React.useEffect(() => {
    if (document.cookie) {
      let cookieArray = document.cookie.split(";");
      let lang = cookieArray.filter((c) => c.indexOf("preferredLanguage") > -1);
      if (lang && lang.length) {
        setPreferredLanguage(lang[0].split("=")[1]);
        i18n.changeLanguage(lang[0].split("=")[1]);
      } else {
        i18n.changeLanguage("en");
      }
    }
  }, []);

  return (
    <div className="App">
      <GTMService gtmId={gtmId} />
      <GTMNoScript gtmId={gtmId} />
      <BrowserRouter>
        <Header lang={preferredLanguage} />
        <Suspense fallback={<div></div>}>
          {/* <Navigate
            from="/:url*(/+)"
            to={window.location.pathname.slice(0, -1)}
          /> */}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/biometric-products" element={<Product />} />
            <Route
              path="/credence-connect-biometric-software"
              element={<CredenceConnect />}
            />
            <Route
              path="/credencetab-biometric-tablet"
              element={<CredenceTab />}
            />
            <Route
              path="/credencetwo-r-handheld-biometric-device"
              element={<CredenceTwoR />}
            />
            <Route
              path="/credenceeco-identifier-biometric-reader"
              element={<CredenceEco />}
            />
            <Route
              path="/credence3-identifier-biometric-reader"
              element={<Credence3 />}
            />
            <Route path="/biometric-starter-kit" element={<GetStarted />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/digital-id" element={<VwCPage />} />
            <Route path="/vwc-web-portal" element={<VwCPortal />} />
            <Route path="/smart-verifier" element={<Tap2iDMobileApp />} />
            <Route path="/tap2id" element={<Tap2iD />} />
            <Route path="/digital-id/faq" element={<Faq />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route
              path="/contact-us-confirmation"
              element={<ContactUsConfirmation />}
            />
            <Route path="/media" element={<Media />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route path="/privacy-statement" element={<PrivacyStatement />} />
            <Route
              path="/press-release/:mediaLink"
              element={<MediaDetails />}
            />
            <Route path="/whitepapers/:mediaLink" element={<MediaPapers />} />
            <Route path="/sitemap" element={<Sitemap />} />
            <Route path="/sitemap/slp" element={<Slp />} />
            {/* <Route
              path="/sitemap.html"
              render={() => {
                window.location.href = "sitemap.html";
              }}
            >
              <Sitemap></Sitemap>
            </Route> */}
          </Routes>
        </Suspense>
      </BrowserRouter>
    </div>
  );
}

export default App;
