import React, { useState, useEffect } from "react";
import "./Header.css";
import { Navbar, Nav, Dropdown, Container, Col } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { NavTabs } from "../../constants/home.js";
import * as cx from "classnames";
import logo from "../../assets/images/Credence-ID-logo.png";
import whitelogo from "../../assets/images/Credence-ID-logo-1.png";
import CidButton from "../CidButton/CidButton.js";
import { useTranslation } from "react-i18next";
// import langchangeicon from "../../assets/svgs/lang-change.svg";
//import langchangeiconBlack from "../../assets/svgs/langChangeBlack.svg";
import MenuIcon from "../../assets/svgs/menuIcon.svg";
export default function Header(props) {
  const [selectedTab, setSelectedTab] = useState("home");
  // const [selectedLang, setSelectedLang] = useState({
  //   text: "English",
  //   key: "en",
  // });
  // const [showLangDropdown, setShowLangDropdown] = useState(false);
  const [showGreenHeader, setShowGreenHeader] = useState(false);
  const [showNavDropdown, setShowNavDropdown] = useState(false);
  const [showProductDropdown, setShowProductDropdown] = useState(false);
  const [isShown, setIsShown] = useState(false);
  const [showDigitalDropdown, setShowDigitalDropDown] = useState(false);
  const [hideMobileSubMenuForProd, setHideMobileSubMenuForProd] =
    useState(true);
  const [hideMobileSubMenuForId, setHideMobileSubMenuForId] = useState(true);
  const [isMobileid, setIsMobileid] = useState(false);
  const { t } = useTranslation();
  // const history = useNavigate();
  const location = useLocation();
  let path = window.location.pathname;

  const onToggleHandler = (isOpen, e, metadata) => {
    if (metadata.source !== "select") {
      setIsShown(isOpen);
    }
  };
  const hideNavBar = () => {
    setIsShown(false);
  };

  if (window.location.pathname.indexOf("press-release") > -1) {
    path = "/press-release";
    //setTab(path)
  } else if (window.location.pathname.indexOf("whitepapers") > -1) {
    path = "/whitepapers";
    //setTab(path)
  }

  useEffect(() => {
    if (
      path === "/digital-id" ||
      path === "/vwc-web-portal" ||
      path === "/tap2id" ||
      path === "/smart-verifier"
    ) {
      setIsMobileid(true);
    } else {
      setIsMobileid(false);
    }
  }, [path]);

  useEffect(() => {
    setTab(path);
  }, []);

  // useEffect(() => {
  //   history.listen((location) => {
  //     setTab(location.pathname);
  //     if (
  //       location.pathname === "/biometric-starter-kit" ||
  //       location.pathname === "/"
  //     ) {
  //       checkTab(location.pathname);
  //     }
  //   });
  // }, [history]);
  useEffect(() => {
    setTab(location.pathname);
    if (
      location.pathname === "/biometric-starter-kit" ||
      location.pathname === "/"
    ) {
      checkTab(location.pathname);
    }
  }, [location.pathname]);

  React.useEffect(() => {
    checkTab(path);
  }, [selectedTab]);

  const checkTab = (path) => {
    if (path === "/") {
      setShowGreenHeader(true);
    } else {
      setShowGreenHeader(false);
    }

    window.addEventListener("scroll", () => {
      if (!window.scrollY) {
        if (path === "/") {
          setShowGreenHeader(true);
        } else {
          setShowGreenHeader(false);
        }
      } else {
        setShowGreenHeader(false);
      }
    });
  };

  // React.useEffect(() => {
  //   if (props.lang === "en" || props.lang === "es") {
  //     let prefLang = languages.filter((l) => l.key === props.lang);
  //     // setSelectedLang(prefLang[0]);
  //   } else {
  //     // setSelectedLang({ text: "English", key: "en" });
  //   }
  // }, [props.lang]);

  const setTab = (path) => {
    window.scrollTo(0, 0);
    switch (path) {
      case "/biometric-products":
      case "/credence-connect-biometric-software":
      case "/credencetwo-r-handheld-biometric-device":
      case "/credencetab-biometric-tablet":
      case "/credenceeco-identifier-biometric-reader":
      case "/credence3-identifier-biometric-reader":
        setSelectedTab("products");
        break;
      case "/digital-id":
      case "/digital-id/faq":
      case "/vwc-web-portal":
      case "/smart-verifier":
      case "/tap2id":
        setSelectedTab("digitalId");
        break;
      case "/about-us":
        setSelectedTab("aboutUs");
        break;
      case "/media":
        setSelectedTab("media");
        break;
      case "/press-release":
        setSelectedTab("media");
        break;
      case "/whitepapers":
        setSelectedTab("media");
        break;
      case "/contact-us":
        setSelectedTab("contactUs");
        break;
      case "/terms-of-service":
        setSelectedTab("");
        break;
      case "/privacy-statement":
        setSelectedTab("");
        break;
      case "/sitemap":
        setSelectedTab("");
        break;
      default:
        setSelectedTab("home");
        break;
    }
  };

  // const onLangSelected = (lang) => {
  //   setSelectedLang(lang);
  //   i18n.changeLanguage(lang.key);
  //   document.cookie = "preferredLanguage=" + lang.key;
  // };

  const mobileSubmenu = (isDigital) => {
    if (isDigital) {
      hideMobileSubMenuForId
        ? setHideMobileSubMenuForId(false)
        : setHideMobileSubMenuForId(true);
    } else {
      hideMobileSubMenuForProd
        ? setHideMobileSubMenuForProd(false)
        : setHideMobileSubMenuForProd(true);
    }
  };
  const handleMenuSelect = (tab) => {
    setSelectedTab(tab.key);
    checkTab(tab.path);
    // setShowGreenHeader(tab.key === "home" ? true : false);
  };
  return (
    <div
      className={cx(
        "header",
        { "green-header": showGreenHeader },
        { "white-header": !showGreenHeader }
      )}
    >
      <Container className="header-container max-width">
        <div className="header-logo">
          <Link to="/">
            {showGreenHeader ? (
              <img src={whitelogo} alt="Credence ID logo" />
            ) : (
              <img src={logo} alt="Credence ID logo" />
            )}
          </Link>
        </div>
        <div className="nav-flex-container">
          <Navbar className="nav-bar" expand="sm">
            <Nav className="nav-container">
              {NavTabs.map((tab, i) => {
                return (
                  <>
                    {tab.key !== "digitalId" ? (
                      <div
                        key={tab.key}
                        className={cx("nav-tabs", {
                          "selected-tab": selectedTab === tab.key,
                        })}
                        onMouseEnter={() =>
                          setShowProductDropdown(tab.path === tab.dropdownPath)
                        }
                        onMouseLeave={() => setShowProductDropdown(false)}
                      >
                        {tab.dropdown ? (
                          <Link
                            onClick={() => {
                              //setSelectedTab(tab.key);
                              //setShowGreenHeader(tab.key === "home" ? true : false);
                              handleMenuSelect(tab);
                            }}
                            to={tab.path}
                            disabled={i > 1}
                          >
                            {t(tab.textKey)}
                            {showProductDropdown && (
                              <div className="nav-dropdown-container">
                                {tab.dropdownTitle && (
                                  <Link
                                    to={tab.dropdownPath}
                                    onClick={() =>
                                      setShowProductDropdown(false)
                                    }
                                  >
                                    <div className="nav-dropdown-title">
                                      {t(tab.dropdownTitle)}
                                    </div>
                                  </Link>
                                )}
                                {tab.dropdown.map((d) => {
                                  return (
                                    <div
                                      key={d.text}
                                      className={cx(
                                        "nav-dropdown-items",
                                        d.class ? d.class : ""
                                      )}
                                      onClick={() =>
                                        setShowProductDropdown(false)
                                      }
                                    >
                                      <Link to={d.path}>
                                        <div className="nav-dropdown-img">
                                          <img
                                            src={d.img}
                                            alt={d.text}
                                            width={d.imgWidth}
                                          />
                                        </div>
                                        <div className="nav-dropdown-text">
                                          {t(d.text)}
                                        </div>
                                      </Link>
                                    </div>
                                  );
                                })}
                              </div>
                            )}
                          </Link>
                        ) : (
                          <Link
                            onClick={() => {
                              handleMenuSelect(tab);
                              //setShowGreenHeader(tab.key === "home" ? true : false);
                            }}
                            to={tab.path}
                            // className={ i === 3 ? "disabled-link" : i === 4 ? " disabled-link" : "" }
                          >
                            {t(tab.textKey)}
                          </Link>
                        )}
                      </div>
                    ) : (
                      <div
                        key={tab.key}
                        className={cx("nav-tabs", {
                          "selected-tab": selectedTab === tab.key,
                        })}
                        onMouseEnter={() =>
                          setShowDigitalDropDown(tab.path === tab.dropdownPath)
                        }
                        onMouseLeave={() => setShowDigitalDropDown(false)}
                      >
                        <Link
                          onClick={() => {
                            //setSelectedTab(tab.key);
                            //setShowGreenHeader(tab.key === "home" ? true : false);
                            handleMenuSelect(tab);
                          }}
                          to={tab.path}
                          disabled={i > 1}
                        >
                          {t(tab.textKey)}
                          {showDigitalDropdown && (
                            <div className="nav-dropdown-container digitalId-dropdown-container">
                              {tab.dropdownTitle && (
                                <Link
                                  to={tab.dropdownPath}
                                  onClick={() => setShowDigitalDropDown(false)}
                                >
                                  <div className="nav-dropdown-title">
                                    {t(tab.dropdownTitle)}
                                  </div>
                                </Link>
                              )}
                              {tab.dropdown.map((d) => {
                                return (
                                  <div
                                    key={d.text}
                                    className={cx(
                                      "nav-dropdown-items",
                                      d.class ? d.class : ""
                                    )}
                                    onClick={() =>
                                      setShowDigitalDropDown(false)
                                    }
                                  >
                                    <Link to={d.path}>
                                      <div className="nav-dropdown-img">
                                        <img
                                          src={d.img}
                                          alt={d.text}
                                          width={d.imgWidth}
                                          height={d.imgHeight}
                                        />
                                      </div>
                                      <div className="nav-dropdown-text">
                                        {t(d.text)}
                                      </div>
                                    </Link>
                                  </div>
                                );
                              })}
                            </div>
                          )}
                        </Link>
                      </div>
                    )}
                  </>
                );
              })}
            </Nav>
          </Navbar>
        </div>
        <div className="header-buttons">
          <div className="button-group">
            <span className="cid-button">
              <CidButton
                buttonText={t("start-now")}
                link="/biometric-starter-kit"
              />
            </span>
            <span className="cid-button hidden-xs hidden-sm">
              <CidButton
                buttonText={t("sign-in")}
                outline="true"
                outlineColor={!showGreenHeader}
                href={
                  isMobileid
                    ? "https://verify.credenceid.com/"
                    : "https://connect.credenceid.com"
                }
              />
            </span>
            <span className="cid-button hidden-lg ">
              <CidButton
                showwhiteIcon={showGreenHeader}
                outline="true"
                outlineColor={!showGreenHeader}
                href={
                  isMobileid
                    ? "https://verify.credenceid.com/"
                    : "https://connect.credenceid.com"
                }
              />
            </span>
            {/* <Dropdown className="lang-dropdown hidden-xs hidden-sm">
              <Dropdown.Toggle className={cx("lang-dropdown-button")}>
                {t(selectedLang.text)}
                <i
                  className="fa fa-chevron-down lang-icon"
                  id="dropdown-basic"
                />
              </Dropdown.Toggle>
              <Dropdown.Menu
                className="lang-dropdown-menu"
                show={showLangDropdown}
              >
                {languages
                  .filter((lang) => lang.key !== selectedLang.key)
                  .map((ln) => {
                    return (
                      <Dropdown.Item
                        key={ln.key}
                        onClick={() => onLangSelected(ln)}
                        className="lang-dropdown-item"
                        onSelect={() => setShowLangDropdown(false)}
                      >
                        {t(ln.text)}
                      </Dropdown.Item>
                    );
                  })}
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown className="lang-dropdown hidden-lg">
              <Dropdown.Toggle className={cx("lang-dropdown-button")}>
                {showGreenHeader ? (
                  <img
                    src={langchangeicon}
                    alt="Credence ID Change website language"
                  ></img>
                ) : (
                  <img
                    src={langchangeiconBlack}
                    alt="Credence ID Change website language"
                  ></img>
                )}
              </Dropdown.Toggle>
              <Dropdown.Menu
                className="lang-dropdown-menu"
                show={showLangDropdown}
              >
                {languages
                  .filter((lang) => lang.key !== selectedLang.key)
                  .map((ln) => {
                    return (
                      <Dropdown.Item
                        key={ln.key}
                        onClick={() => onLangSelected(ln)}
                        className="lang-dropdown-item"
                        onSelect={() => setShowLangDropdown(false)}
                      >
                        {t(ln.text)}
                      </Dropdown.Item>
                    );
                  })}
              </Dropdown.Menu>
            </Dropdown> */}
          </div>
        </div>
        <div className="nav-icon-bar-container">
          <Dropdown
            show={isShown}
            onToggle={(isOpen, e, metadata) =>
              onToggleHandler(isOpen, e, metadata)
            }
          >
            <Dropdown.Toggle className="menu-icon">
              <img
                className={cx({ blackIcon: !showGreenHeader })}
                src={MenuIcon}
                alt="Back"
              ></img>
              {/* <i className="fa fa-bars" id="dropdown-basic-nav"/> */}
            </Dropdown.Toggle>
            <Dropdown.Menu className="nav-dropdown-menu" show={showNavDropdown}>
              <Dropdown.Item>
                <img
                  className="credenceid-menu2"
                  src={logo}
                  alt="Credence ID logo"
                />
              </Dropdown.Item>
              {NavTabs.map((tab) => {
                return (
                  <>
                    {tab.key !== "digitalId" ? (
                      <>
                        {tab.dropdown ? (
                          <>
                            <Dropdown.Item
                              key={tab.key}
                              className={cx("nav-dropdown-item", {
                                "selected-tab": selectedTab === tab.key,
                              })}
                              onSelect={() => {
                                setSelectedTab(tab.key);
                                setShowNavDropdown(false);
                              }}
                            >
                              <Link
                                onClick={() => hideNavBar(true)}
                                to={tab.path}
                              >
                                {t(tab.textKey)}
                              </Link>
                              <a
                                onClick={() => mobileSubmenu(false)}
                                className="menu-dropDown-icon"
                              >
                                <i
                                  class={cx(
                                    "arrow",
                                    hideMobileSubMenuForProd ? "down" : "up"
                                  )}
                                ></i>
                              </a>
                              <ul
                                className={cx("hamburger-subMenu", {
                                  hideSubmenu: hideMobileSubMenuForProd,
                                })}
                              >
                                <li>
                                  <div
                                    className="hamburger-dropdown-items hamburger-prodctList"
                                    onClick={() =>
                                      setShowProductDropdown(false)
                                    }
                                  >
                                    <Link
                                      onClick={() => hideNavBar(true)}
                                      to={tab.dropdownPath}
                                    >
                                      {t(tab.dropdownTitle)}
                                    </Link>
                                  </div>
                                </li>
                                {tab.dropdown.map((d) => {
                                  return (
                                    <>
                                      <li>
                                        <div
                                          key={d.text}
                                          className={cx(
                                            "hamburger-dropdown-items",
                                            d.class ? d.class : ""
                                          )}
                                          onClick={() =>
                                            setShowProductDropdown(false)
                                          }
                                        >
                                          <Link
                                            to={d.path}
                                            onClick={() => hideNavBar(true)}
                                          >
                                            <div className="hamburger-dropdown-img">
                                              <img
                                                src={d.img}
                                                alt={d.text}
                                                width={d.imgMobWidth}
                                              />
                                            </div>
                                            <div className="hamburger-dropdown-text">
                                              {t(d.text)}
                                            </div>
                                          </Link>
                                        </div>
                                      </li>
                                    </>
                                  );
                                })}
                              </ul>
                            </Dropdown.Item>
                          </>
                        ) : (
                          <>
                            <Dropdown.Item
                              key={tab.key}
                              className={cx("nav-dropdown-item", {
                                "selected-tab": selectedTab === tab.key,
                              })}
                              onSelect={() => {
                                setSelectedTab(tab.key);
                                setShowNavDropdown(false);
                              }}
                            >
                              <Link
                                to={tab.path}
                                onClick={() => hideNavBar(true)}
                              >
                                {t(tab.textKey)}
                              </Link>
                            </Dropdown.Item>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <Dropdown.Item
                          key={tab.key}
                          className={cx("nav-dropdown-item", {
                            "selected-tab": selectedTab === tab.key,
                          })}
                          onSelect={() => {
                            setSelectedTab(tab.key);
                            setShowNavDropdown(false);
                          }}
                        >
                          <Link onClick={() => hideNavBar(true)} to={tab.path}>
                            {t(tab.textKey)}
                          </Link>
                          <a
                            onClick={() => mobileSubmenu(true)}
                            className="menu-dropDown-icon"
                          >
                            <i
                              class={cx(
                                "arrow",
                                hideMobileSubMenuForId ? "down" : "up"
                              )}
                            ></i>
                          </a>
                          <ul
                            className={cx("hamburger-subMenu", {
                              hideSubmenu: hideMobileSubMenuForId,
                            })}
                          >
                            <li>
                              <div
                                className="hamburger-dropdown-items hamburger-prodctList"
                                onClick={() => setShowDigitalDropDown(false)}
                              >
                                <Link
                                  onClick={() => hideNavBar(true)}
                                  to={tab.dropdownPath}
                                >
                                  {t(tab.dropdownTitle)}
                                </Link>
                              </div>
                            </li>
                            {tab.dropdown.map((d) => {
                              return (
                                <>
                                  <li>
                                    <div
                                      key={d.text}
                                      className={cx(
                                        "hamburger-dropdown-items",
                                        d.class ? d.class : ""
                                      )}
                                      onClick={() =>
                                        setShowDigitalDropDown(false)
                                      }
                                    >
                                      <Link
                                        to={d.path}
                                        onClick={() => hideNavBar(true)}
                                      >
                                        <div className="hamburger-dropdown-img">
                                          <img
                                            src={d.img}
                                            alt={d.text}
                                            width={d.imgMobWidth}
                                          />
                                        </div>
                                        <div className="hamburger-dropdown-text">
                                          {t(d.text)}
                                        </div>
                                      </Link>
                                    </div>
                                  </li>
                                </>
                              );
                            })}
                          </ul>
                        </Dropdown.Item>
                      </>
                    )}
                  </>
                );
              })}

              <Dropdown.Item className="footer-nav-item">
                <div className="footer-container">
                  <Container className="max-width">
                    <Col className="hidden-lg hidden-sm" xs={12}>
                      <div className="footer-texts footer-page-linkWrap">
                        <Link
                          className="footer-mobile-pageLink"
                          to="/terms-of-service"
                        >
                          Terms of Service |
                        </Link>
                        <Link
                          className="footer-mobile-pageLink2"
                          to="/privacy-statement"
                        >
                          Privacy Statement
                        </Link>
                      </div>
                    </Col>
                    <Col xs={12}>
                      <div className="footer-texts">{t("headquarters")}</div>
                    </Col>
                  </Container>
                  {/* <Container className="max-width footer-top-container">
										<Col className='getIn-touch-wrap-mobile' md={3} xs={12}>
												<div className="footer-titles green-titles">get-in-touch</div>
												<div className="footer-titles">{t("get-in-touch-no")}</div>
										</Col>
										<Col md={4} xs={12} className="footer-follow-link-mobile">
												<div className="footer-titles green-titles">{t("follow-us")}</div>
												<div>
													<a href="https://twitter.com/CredenceID" target="_blank" role="button" rel="noopener noreferrer" data-toggle="collapse" data-target=".navbar-collapse">
														<img src={twitterIcon} alt="Credence ID Social Media - Twitter Logo" className="padding-right-20" />
													</a>
													<a href="https://www.facebook.com/CredenceID/" target="_blank" rel="noopener noreferrer">
														<img src={facebookIcon} alt="Credence ID Social Media - Facebook Logo" className="padding-right-20" />
													</a>
													<a href="https://www.linkedin.com/mwlite/company/credence-id" target="_blank" rel="noopener noreferrer">
														<img src={linkedInIcon} alt="Credence ID Social Media - LinkedIn Logo" />
													</a>
												</div>
										</Col>
										</Container> */}
                </div>
                <div className="copyright">
                  <span>{t("copyright")}</span>
                </div>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </Container>
    </div>
  );
}
